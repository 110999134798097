document.addEventListener('click', (e) => {
  const obLinkHrefEl = e.target.closest('[data-oblink-href]')
  if (obLinkHrefEl) {
    const href = atob(obLinkHrefEl.dataset.oblinkHref)
    const target = obLinkHrefEl.dataset.oblinkTarget || obLinkHrefEl.getAttribute('target') || null

    if (e.ctrlKey || target === '_blank') {
      const newWindow = window.open(href)
      if (!e.ctrlKey) {
        newWindow.focus()
      }
    } else {
      document.location.href = href
    }
  }
}, {
  passive: true
})
